import { render, staticRenderFns } from "./Tgame365Ruollet.vue?vue&type=template&id=340f1e50&scoped=true&"
import script from "./Tgame365Ruollet.vue?vue&type=script&lang=js&"
export * from "./Tgame365Ruollet.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "340f1e50",
  null
  
)

export default component.exports